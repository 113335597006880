import React from 'react';
import Logo from "../Images/ecomlogo.jpg";
import { FaBagShopping } from "react-icons/fa6";
import "../css/navbar.css";
import {useSelector} from "react-redux";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Navbar = ({sec1,sec2,sec3,sec4}) => {

  const cardCount  = useSelector(state =>state.card.card)
  return (
    <div className='md:px-20 md:py-5  py-4  p-[20px]'>
        <div className='flex justify-between gap-2'>
        <div>
       <span className='md:text-[24px] text-[14px] md:ml-0 ml-1  font-600 uppercase text-white'>Snap</span>
        <span className='md:text-[24px] text-[14px] md:ml-2 ml-1 font-600 uppercase text-[#FF4A56]'>Deals</span> 
      </div>
      <div className='flex gap-4 md:mt-3 mt-0 md:mr-28  mr-0 navbarsection cursor-pointer'>
          <span>{sec1}</span>
          <span>{sec2}</span>
          <span>{sec3}</span>
          <span>{sec4}</span>
      </div>
       <div className='mr-[10px] md:mr-[0px] md:mt-3'>
        <Link to="/addcart">
       <span className='relative'><FaBagShopping color='#FF4A56'/> <span class="badge absolute left-2 bottom-1 ">{cardCount.length}</span></span>
       </Link>
       {/* <FaBagShopping color='#FF4A56'/> <span className='text-white'>{cardCount.length}</span> */}
       </div>
       </div>
    </div>
  )
}

export default Navbar
